import React from "react";
import BsIcon from "../bsIcon";
import {useTranslation} from "react-i18next";
import {Badge} from "react-bootstrap";

function PaidBadge() {
    const { t } = useTranslation();

    return <Badge className="ms-2" bg="green-300" text="black">
        <BsIcon classNames="me-1" icon={'check2-circle'}/>
        {t('admin.paid')}
    </Badge>
}

export default PaidBadge;