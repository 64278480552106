import React from "react";
import {useNavigate, useLocation} from 'react-router-dom';
import ContainerFluid from "../components/containers/container_fluid";
import BsIcon from "../components/bsIcon";
import { useTranslation } from 'react-i18next';


function ProfilePage() {
    let navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const menuItems = { notification_settings: "bell",
                             subscriptions: "wallet2",
                             groups: "people",
                             personal: "person" }

    return Object.entries(menuItems).map(([menuName, icon]) => (
            <ContainerFluid key={menuName} shadow={true} className="justify-content-between bg-white mb-2 px-3 py-2" onClick={() => navigate(`${location.pathname}/${menuName}`)}>
                <div className="fs-5">
                    <BsIcon icon={icon}/>
                    <span className="ps-3">{t(menuName)}</span>
                </div>
                <BsIcon icon="caret-right"/>
            </ContainerFluid>
    ))
}

export default ProfilePage;
