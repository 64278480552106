import React from "react";
import {Card} from "react-bootstrap";

function BsCard({title, children, className, noStyle}) {
    return <Card bg="white" className={"col-md-6 shadow border-0 " + className} style={noStyle ? {} : {marginBottom: "90px"}}>
        <Card.Body>
            {title && <Card.Title className="text-center">{ title }</Card.Title>}
            {children}
        </Card.Body>
    </Card>
}

export default BsCard;