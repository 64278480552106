import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import BodyWrapper from "../../components/containers/bodyWrapper";
import SubscriptionCard from "../../components/cards/subscriptionCard";
import {performAjaxRequest} from "../../helpers";
import BsIcon from "../../components/bsIcon";

function SubscriptionsPage(props) {
    const { t } = useTranslation();
    const [subscriptions, setSubscriptions] = useState(null)
    const [showInactive, setShowInactive] = useState(2);

    useEffect(() => {
        performAjaxRequest('GET', '/subscriptions.json', null, {success: response => {
                setSubscriptions(response)
            }})
    }, [])

    const activeSubscriptions = useMemo(() => {
        return subscriptions?.filter(subscription => subscription.active === true)
    }, [subscriptions])

    const inactiveSubscriptions = useMemo(() => {
        return subscriptions?.filter(subscription => subscription.active !== true)
            .sort((a, b) => new Date(b.end_date) - new Date(a.end_date));
    }, [subscriptions])

    return <BodyWrapper>
        {activeSubscriptions?.length > 0 ? activeSubscriptions.map((subscription) =>
            <SubscriptionCard key={subscription.id} subscription={subscription} />) :
            <div className="row">
                <div className="col">
                    <span> {t('no_active_subscription')} </span>
                </div>
            </div>}

        <div className="d-flex my-4 align-items-center">
            <BsIcon icon='clock-history'/>
            <span className="ms-1">{t('inactive_subscriptions')}</span>
        </div>

        {inactiveSubscriptions?.length > 0 && inactiveSubscriptions.slice(0, showInactive).map((subscription) =>
                <SubscriptionCard key={subscription.id} subscription={subscription}/>
        )}

        {showInactive < inactiveSubscriptions?.length &&
            <div onClick={() => setShowInactive(showInactive + 4)} className="d-flex my-4 justify-content-end">
                <span className="text-primary">{t('show_more')}</span>
            </div>}
    </BodyWrapper>
}

export default SubscriptionsPage;
