import React from "react";
import { useTranslation } from 'react-i18next';
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Email from "../components/forms/fields/email";
import Password from "../components/forms/fields/password";
import TextSmall from "../components/textSmall";
import Submit from "../components/forms/fields/submit";
import {performAjaxRequest} from "../helpers";
import BasicForm from "../components/forms/basicForm";

function LoginPage(props) {
    const { t } = useTranslation();

    const onSubmit = (formData) => {
        performAjaxRequest("POST", "/sign_in.json", formData, {props: props})
    }

    return <BasicForm keys={["email", "password"]}
                      title={ t('login') }
                      onSubmit={onSubmit} >
                <Email autoFocus={true} noRequired={true}/>
                <Password />
                <Form.Group className="mb-3 small">
                    <Link className="fs-6" to="/password_resets/new">{ t('forgot_password') }</Link>
                </Form.Group>

                <Submit text={ t('login') } />

                <div className="mt-5 text-center">
                    <span>{ t('not_signed_up') }</span>
                    <br/>
                    <Link to="/users/new">{ t('registration_link') }</Link>
                </div>
    </BasicForm>
}

export default LoginPage;