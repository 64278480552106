import React from "react";
import BsIcon from "../bsIcon";
import {useTranslation} from "react-i18next";
import {Badge} from "react-bootstrap";

function AppliedBadge() {
    const { t } = useTranslation();

    return <Badge className="ms-1" bg="green-300" text="black">
        <BsIcon icon={'check2-circle'}/>
        {t('applied')}
    </Badge>
}

export default AppliedBadge;