import React, {useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {Button, Dropdown} from "react-bootstrap";
import {performAjaxRequest} from "../../helpers";
import BsIcon from "../../components/bsIcon";
import {useNavigate} from "react-router-dom";
import SubscriptionModal from "../../components/modals/subscriptionModal";
import Autocomplete from "../../components/autocomplete";
import AccordionWrapper from "../../components/accordionWrapper";
import ApplicationModal from "../../components/modals/applicationModal";
import ApplicationsTable from "../../components/tables/applicationsTable";
import TextSmall from "../../components/textSmall";
import UsersTable from "../../components/tables/usersTable";
import GuestUserModal from "../../components/modals/guestUserModal";

function AdminApplicationsPage(props) {
    const {id} = useParams();
    const {t} = useTranslation();
    const applicationModalRef = useRef();
    const subscriptionModalRef = useRef();

    const navigate = useNavigate();

    const [event, setEvent] = useState(null);
    const [applications, setApplications] = useState(null);
    const [application, setApplication] = useState(null);
    const [newApplicationUser, setNewApplicationUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const guestUserModalRef = useRef();
    const handleShowGuestUserModal = () => guestUserModalRef?.current?.showModal();

    const fetchApplications = () => {
        performAjaxRequest(
            'GET',
            `/admin/events/${id}/applications.json`,
            null,
            {success:(response) => {setApplications(response);}}
        )
    };

    useEffect(() => {
        performAjaxRequest(
            'GET',
            `/admin/events/${id}.json`,
            null,
            {success:(response) => {setEvent(response);}}
        )
        fetchApplications();
    }, []);

    const unappliedUsers = useMemo(() => {
        return event?.group.users?.filter((user) => !applications?.map(application => application.user.id).includes(user.id));
    }, [event, applications])

    const openApplicationModal = (id) => {
        setNewApplicationUser(id)
        applicationModalRef?.current?.showModal()
    }

    const autocompleteSelect = function (event, ui) {
        const autocompleteId = ui.item.id

        if (applications?.map(a => a.user.id).includes(autocompleteId)) { // already applied
            const existingApplication = applications.find(a => a.user.id === autocompleteId);
            if (existingApplication) {
                setApplication(existingApplication)
                subscriptionModalRef?.current?.showModal()
            }
        } else {
            openApplicationModal(ui.item)
        }
    }

    const handleClick = (e) => {
        e.preventDefault();
        if (isLoading) {
            return;
        }

        if (window.confirm(t('admin.confirm_default'))) {
            setIsLoading(true);
            performAjaxRequest('PUT', `/admin/events/${id}/close`, null, {props: props})
        }
    };

    const modalButton = application => (
        <Button variant="white" type="submit" data-bs-target="#subscriptionModal" data-bs-toggle="modal" onClick={() => {setApplication(application)}} >
            { t('admin.modify') }
        </Button>
    )

    const addButton = user => (<Button variant="white" type="submit" onClick={() => openApplicationModal(user)}>
        {t('admin.add')}
    </Button>)

    return <div>
        {event?.closed ? <BsIcon role="button" icon="arrow-left-circle" classNames="fs-1  text-primary"
                                 onClick={() => navigate(-1)}/> : <>
            <div className='d-flex justify-content-between'>
                <BsIcon role="button" icon="arrow-left-circle" classNames="fs-1  text-primary"
                        onClick={() => navigate(-1)}/>

                <div className='d-flex'>
                    <AccordionWrapper button={
                        <Button variant="primary" type="submit" className="rounded-0 rounded-start">
                            <BsIcon classNames="me-2" icon="person-fill"/>{t('admin.new_applications')}
                        </Button>
                    }>
                        <Autocomplete url={`/admin/users.json`} selectCallback={autocompleteSelect} sourceKey="users"/>
                    </AccordionWrapper>

                    <Dropdown as="div" className="my-3">
                        <Dropdown.Toggle variant="primary" className="border-start rounded-0 rounded-end"></Dropdown.Toggle>
                        <Dropdown.Menu as="div" className="p-0" style={{minWidth: '203px'}}>
                            <Dropdown.Item as="button" className="btn btn-white" onClick={handleShowGuestUserModal} >
                                <BsIcon classNames="me-2" icon="backpack"/> {t('admin.guest_registration')}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </>}

        <div className="d-flex flex-column mb-3">
            <h4>{event?.name}</h4>
            <span className="text-secondary">{event?.datetime}</span>
        </div>

        <ApplicationsTable applications={applications} actionColumn={modalButton} fetchApplications={fetchApplications}
                           readOnly={event?.closed}/>

        {!event?.closed && <>
            <div className="d-flex justify-content-between my-4">
                <a className="btn btn-white" data-method="put" data-confirm={t('admin.confirm_default')}
                   href={`/admin/events/${id}/cancel`}>
                    <BsIcon classNames="me-1" icon='x-circle'/>
                    {t('admin.cancel_event')}
                </a>

                <a className={`btn btn-white`} onClick={handleClick} disabled={isLoading} >
                    <BsIcon classNames="me-1" icon='check-all'/>
                    {t('admin.close_event')}
                </a>
            </div>

            {unappliedUsers?.length > 0 && <div className="mt-5">
                <AccordionWrapper arrow={true} classNames="p-0" button={<h4>{t('admin.more_dancer')}</h4>}>
                    <TextSmall text={t('admin.more_dancer_info', {group: event?.group.name})}/>
                    <UsersTable users={unappliedUsers} actionColumn={addButton} forApplication={true}/>
                </AccordionWrapper>
            </div>
            }

            <GuestUserModal ref={guestUserModalRef} eventId={id} fetchApplications={fetchApplications} {...props}/>

            <SubscriptionModal ref={subscriptionModalRef}
                               plans={event?.group.plans}
                               event_id={event?.id}
                               group_id={event?.group.id}
                               application={application}
                               handleSubscriptionBuyFailed={() => {
                                   alert(t('admin.subscription_creation_failed'))
                               }}
                               handleSubscriptionBought={fetchApplications}/>
            <ApplicationModal ref={applicationModalRef}
                              user={newApplicationUser}
                              group={event?.group}
                              fetchApplications={fetchApplications}
                              {...props} />
        </>}
    </div>
}

export default AdminApplicationsPage;
