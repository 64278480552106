import ContainerFluid from "../containers/container_fluid";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {performAjaxRequest} from "../../helpers";
import {Button} from "react-bootstrap";
import BsIcon from "../bsIcon";
import AccordionWrapper from "../accordionWrapper";
import AppliedBadge from "../badges/appliedBadge";

function EventCard({ event, applicationId, subscription, settings, setApplications, props}) {
    const { t } = useTranslation();
    const checked = !!applicationId
    const [isProcessing, setIsProcessing] = useState(false);

    const disabled = () => {
        if (isProcessing) return true

        if (checked) {
            let threshold = new Date(event.datetime)
            threshold.setHours(threshold.getHours() - settings.application_cancellation_limit)

            return new Date() > threshold
        } else if (settings.apply_before_event_limit) {
            let threshold = new Date(event.datetime)
            threshold.setHours(threshold.getHours() - settings.apply_before_event_limit)

            return new Date() > threshold
        } else {
            return false
        }

    }

    const handleClick = async () => {
        if (disabled()) {
            return; // Ignore clicks while processing or disabled
        }

        setIsProcessing(true);

        if (checked) {
            await performAjaxRequest(
                'DELETE',
                `/applications/${applicationId}`,
                null,
                {success: successCallback,
                         props: props })
        } else {
            await performAjaxRequest(
                'POST',
                '/applications',
                { event_id: event.id },
                {success: successCallback,
                        props: props})
        }

        setIsProcessing(false);
    };

    const successCallback = async () => {
        await performAjaxRequest(
            'GET',
            '/applications.json',
            null,
            {success: response => {setApplications(response)}})
    };

    return <ContainerFluid className=" bg-white my-2 p-3" noFlex={true} shadow={true} dontWrap={true}>
        <div className="d-flex flex-row justify-content-between align-items-center">
            <h5>{event.group.name}</h5>
            {applicationId && <AppliedBadge />}
        </div>
        <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-column m-1">
                <span className='small text-secondary'>
                    <BsIcon classNames="me-1" icon={'calendar-event'}/>
                    {event.datetime}
                </span>
                <span className='small text-secondary'>
                    <BsIcon classNames="me-1" icon={'geo-alt'}/>
                    {event.group?.short_address}
                </span>
                <span className='small text-secondary'>
                    <BsIcon classNames="me-1" icon={'person-raised-hand'}/>
                    {event.group.teachers_name}
                </span>

                {applicationId && subscription && <span className='small text-secondary'>
                        <BsIcon classNames="me-1" icon={`${subscription.plan.icon.replace("bi-", "")}`}/>
                    {`${subscription.plan.name} ${t('bought')}`}
                     </span>
                }

                {applicationId && !subscription && <span className='small text-secondary'>
                            <BsIcon classNames="me-1" icon={"cash"}/>
                    {t('cash_payment')}
                     </span>
                }

                {!applicationId && event.group?.has_active_subscription &&
                    <span className='small text-secondary'>
                        <BsIcon classNames="me-1"
                                icon={`${event.group?.has_active_subscription.plan.icon.replace("bi-", "")}`}/>
                        {`${event.group?.has_active_subscription.plan.name} ${t('bought')}`}
                    </span>
                }
            </div>
        </div>

        {applicationId && <AccordionWrapper arrow={false}
                                            my='0'
                                            classNames="px-0 py-1"
                                            button={<span className="text-primary text-end small">
                                                    {t('details')}
                                                </span>}>
            <div className="d-flex flex-row justify-content-center pt-1">
                <Button variant="white" className="w-100" onClick={handleClick}> {t('unapply')} </Button>
            </div>
        </AccordionWrapper>}

        {!applicationId &&
            <div className="d-flex flex-row justify-content-center pt-2">
                <Button variant="primary" className="w-100" onClick={handleClick}> {t('apply')} </Button>
            </div>
        }
    </ContainerFluid>
}

export default EventCard;