import React from "react";
import {useTranslation} from "react-i18next";
import {Badge} from "react-bootstrap";
import BsIcon from "../bsIcon";

function InactiveBadge() {
    const { t } = useTranslation();

    return <Badge className="ms-2" bg="warning" text="black">
        <BsIcon icon='clock-history' classNames="me-1" />
        {t('inactive_subscription')}
    </Badge>
}

export default InactiveBadge;