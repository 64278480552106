import React, {forwardRef} from "react";
import {useTranslation} from 'react-i18next';
import Modal from "./modal";
import {performAjaxRequest} from "../../helpers";
import BsIcon from "../bsIcon";
import TextPrimary from "../textPrimary";
import SubscriptionCard from "../cards/subscriptionCard";

const SubscriptionModal = forwardRef(({plans, event_id, group_id, application, handleSubscriptionBought, handleSubscriptionBuyFailed}, ref) => {
    const { t } = useTranslation();

    const buySubscription = plan_id => {
        $('button.btn-close').click()

        performAjaxRequest(
            'POST',
            "/admin/subscriptions.json",
            {subscription: {plan_id: plan_id, user_id: application.user.id, group_id: group_id, event_id: event_id}},
            {success: (response) => {handleSubscriptionBought()},
                    error: () => {handleSubscriptionBuyFailed()}}
        )
    }

    return <Modal ref={ref} id="subscriptionModal" title={t('admin.add_subscription')}>
        <TextPrimary br={true} text={application?.user?.full_name}/>
        {application?.paid ?
            <>
                <span>{t('admin.subscription_paid')}</span>
                <SubscriptionCard subscription={application.subscription} adminLink={true} />
            </> :
            <div className="d-block d-grid my-4">
                {plans?.map((plan) => {
                    return <button key={plan.id}
                                   name="button"
                                   type="button"
                                   className="btn btn-white rounded-3 mx-4 my-2"
                                   onClick={() => {buySubscription(plan.id)}}>
                        <i className={"bi fs-4 align-middle " + plan.icon}></i>
                        <span className="align-middle m-2">{plan.name}</span>
                    </button>
                })}
            </div>
        }

        <p className="small mt-4 text-center">
            <a data-method="delete" data-confirm={t('admin.confirm_delete_application')}
               href={`/admin/events/${event_id}/applications/${application?.id}`}>
                <BsIcon classNames="me-1" icon={'trash'}/>
                {t('admin.delete_application')}
            </a>
        </p>
    </Modal>
}
);

export default SubscriptionModal;
