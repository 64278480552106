import React from "react";
import {useTranslation} from "react-i18next";
import {Table} from "react-bootstrap";
import BsIcon from "../bsIcon";
import PaidBadge from "../badges/paidBadge";
import {v4 as uuid} from "uuid";
import ContainerFluid from "../containers/container_fluid";

function UsersTable({users, actionColumn, forApplication}) {
    const { t } = useTranslation();

    const genderIcon = gender => ('gender-' + gender )
    const kindIcon = gender => {
        return {
            "guest": "backpack",
            "user": "person",
            "admin": "person-badge",
            "superadmin": "person-badge",
        }[gender]
    }

    const nameWithIcon = user => {
        return <span className="fs-6">
            {user.full_name}
            { forApplication ? (user.has_active_subscription && <PaidBadge/>) :
                <span className="ms-1">
                    {user.email_confirmed ?
                        <BsIcon icon="check2-circle" classNames="text-success"/> :
                        <BsIcon icon="exclamation-circle" classNames="text-warning"/>}
                </span>
            }
        </span>
    }

    return <>
        <ContainerFluid dontWrap={true} noFlex={true}  className="d-md-none p-0 mt-4">
            {users?.map((user) => (
                 <div key={uuid()} className="d-flex flex-row justify-content-between border-0 rounded-3 shadow-sm p-2 mt-3">
                    <div className="d-flex flex-column" style={{maxWidth: '70%'}}>
                        {nameWithIcon(user)}
                        <span className="d-md-none">
                            <BsIcon icon={kindIcon(user.kind)}/>
                            <span className="d-md-none small text-secondary ms-1">{t('admin.kinds.' + user.kind)}</span>
                            <br className="d-md-none"/>
                            <div className="d-md-none small text-secondary text-truncate">
                                <BsIcon icon="envelope"/>
                                <span className="ms-1">{user.email}</span>
                            </div>
                        </span>
                    </div>
                    <div className="d-flex flex-column pe-0 text-end">
                        {actionColumn(user)}
                        {user.gender && <div className="d-md-none small text-secondary mt-auto">
                            <BsIcon icon={genderIcon(user.gender)}/>
                            <span className="ms-1">{t(user.gender)}</span>
                        </div>}
                    </div>
                </div>
            ))}
        </ContainerFluid>
        <Table responsive className="d-none d-md-table mt-4">
            <tbody>
                {users?.map((user) => (
                    <tr key={uuid()}>
                        <td className="ps-0 text-start">
                            {nameWithIcon(user)}
                        </td>
                        <td className="text-secondary">
                            <span>{user.email}</span>
                        </td>
                        <td className="text-secondary">
                            <BsIcon icon={kindIcon(user.kind)}/>
                            <span className="ms-1">{t('admin.kinds.' + user.kind)}</span>
                        </td>
                        <td className="text-secondary">
                            <BsIcon icon={genderIcon(user.gender)}/>
                            <span className="ms-1">{t(user.gender)}</span>
                        </td>
                        <td className="text-secondary text-end">
                            {actionColumn(user)}
                        </td>
                    </tr>
                ))}
            </tbody>
    </Table>
    </>
}

export default UsersTable;
