import React from "react";
import {useTranslation} from "react-i18next";

function Back(props) {
    const { t } = useTranslation();

    const onClick = (e) => {
        e.preventDefault()
        props.setPage(props.previousPage)
    }

    return <p className="mt-3 text-center">
        <a className="fs-6" href="#" onClick={onClick}>{ t('back') }</a>
    </p>
}

export default Back;