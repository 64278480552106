import React, {useMemo, useState} from "react";
import AccordionWrapper from "../accordionWrapper";
import SubscriptionCard from "../cards/subscriptionCard";
import {useTranslation} from "react-i18next";
import {Alert, Button, Form, InputGroup} from "react-bootstrap";
import {performAjaxRequest} from "../../helpers";

function AdminSubscriptionsSection({user, allGroups, groupIds, onSubscriptionSuccess, ...props}) {
    const { t } = useTranslation()

    const [validated, setValidated] = useState(false);
    const [showMessage, setShowMessage] = useState(false)

    const [groupId, setGroupId] = useState(null);
    const [planId, setPlanId] = useState(null);

    const groups = useMemo(() => {
        const activeSubscriptions = user?.subscriptions.filter(s => s.active && !s.combined)
        const activeCombinedSubscriptions = user?.subscriptions.filter(s => s.active && s.combined)


        return allGroups?.filter(group => groupIds?.includes(group.id) &&
            !(activeSubscriptions.map(s => s.group.id).includes(group.id) || activeCombinedSubscriptions.flatMap(s => s.groups.map(g => g.id)).includes(group.id))
        ) || [];
    }, [allGroups, groupIds, user]);

    const plans = useMemo(() => {
        const allPlans = groups?.flatMap(group => group.plans) || [];
        let filteredPlans = groupId ? allPlans.filter(p => groups.find(group => group.id === parseInt(groupId))?.plans.includes(p)) : allPlans;

        return Array.from(new Set(filteredPlans.map(plan => plan.id))).map(id => filteredPlans.find(plan => plan.id === id));
    }, [groups, planId, groupId]);


    const fourteenDaysAgo = new Date();
    fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);

    const filteredSubscriptions = useMemo(() => {
        const subscriptions = user?.subscriptions || [];
        const result = subscriptions.filter(subscription => new Date(subscription.created_at) >= fourteenDaysAgo || subscription.active );

        return Array.from(result.values());
    }, [user, fourteenDaysAgo]);

    const sortedSubscriptions = useMemo(() =>
        filteredSubscriptions.slice().sort((a, b) =>
            new Date(b.end_date) - new Date(a.end_date)
        ), [filteredSubscriptions]);

    const onSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.stopPropagation();
        } else {
            performAjaxRequest(
                'POST',
                '/admin/subscriptions.json',
                {subscription: {user_id: user.id, group_id: groupId, plan_id: planId}},
                {
                    success: () => {
                        setGroupId('')
                        setPlanId('')
                        setValidated(false)
                        setShowMessage(true)
                        onSubscriptionSuccess()
                    },
                    props: props
                }
            )
        }

        setValidated(true);
    }

    return  <AccordionWrapper arrow={true} rounded={true} button={<h4>{t('admin.subscriptions')}</h4>}>
        {sortedSubscriptions.length > 0 ? sortedSubscriptions.map((sub) => (<SubscriptionCard key={sub.id} adminLink={true} subscription={sub} />))
            :
            <div className="row">
                <div className="col">
                    <span> {t('no_active_subscription')} </span>
                </div>
            </div>
        }

        <h5 className="my-3">{t('admin.add_subscription')}</h5>

        {showMessage && <Alert variant="success" className="w-100 small p-2">
            {t('admin.successful_subscription_creation')}
        </Alert>}

        <Form noValidate validated={validated} onSubmit={onSubmit}>
            <InputGroup className="justify-content-end">
                <Form.Label className="p-2 my-2 me-4">{t('group')} </Form.Label>
                <Form.Select size="sm" id="group" className="my-2 bg-white flex-grow-0 p-2 rounded"
                             style={{"width": "65%"}}
                             onChange={e => setGroupId(e.target.value)}
                             value={groupId || ''}
                             required >
                    <option value="">{t('admin.choose')}</option>
                    {groups?.sort((a, b) => a.name_with_location.localeCompare(b.name_with_location))
                        .map((group, index) => (<option value={group.id} key={index}>{group.name_with_location}</option>))}
                </Form.Select>
            </InputGroup>
            <InputGroup className="justify-content-end">
                <Form.Label className="p-2 mb-2 mb-0 me-4">{t('admin.plan')} </Form.Label>
                <Form.Select size="sm" id="group" className="mb-2 bg-white flex-grow-0 p-2 rounded"
                             style={{"width": "65%"}}
                             onChange={e => setPlanId(e.target.value)}
                             value={planId || ''}
                             required >
                    <option value="">{t('admin.choose')}</option>
                    {plans?.map((plan, index) => <option value={plan.id} key={index}>{plan.name}</option>)}
                </Form.Select>
            </InputGroup>
            <InputGroup className="justify-content-end">
                <Button className="my-3" variant="primary" type="submit">{ t('admin.add') }</Button>
            </InputGroup>
        </Form>

    </AccordionWrapper>
}

export default AdminSubscriptionsSection;