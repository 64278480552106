import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import EventCard from "../components/cards/eventCard";

function EventsPage(props) {
    const [events, setEvents] = useState(null);
    const [applications, setApplications] = useState(null);
    const { t } = useTranslation();

    const fetchData = async () => {
        const [applicationsResponse, eventsResponse] = await Promise.all([
            $.get('/applications.json'),
            $.get('/events.json')
        ]);
        setApplications(applicationsResponse);
        setEvents(eventsResponse);
    };

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            fetchData();
        }
    };

    useEffect(() => {
        fetchData();
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [])

    const renderWeek = () => {
       return events?.map((event) => {
           const application = applications?.find(application => application.event_id === event.id)

           return <EventCard event={event}
                             applicationId={application?.id}
                             subscription={application?.subscription}
                             setApplications={setApplications}
                             {...props} />
         }
       )
    }

    if(props.currentUser.groups.length === 0) {
        return <span dangerouslySetInnerHTML={{__html: t('no_groups_message', {userId: props.currentUser.id,}),}}/>
    } else if (events && Object.keys(events).length > 0) {
        return <div style={{marginBottom: "90px"}}>
            <div className="my-4">
                <span dangerouslySetInnerHTML={{__html: t('cant_find_groups_message', {userId: props.currentUser.id}),}}/>
            </div>
            {renderWeek()}
        </div>
    } else {
        return <span> {t('no_events')} </span>
    }
}

export default EventsPage;